// import React,{ useState, useEffect } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from 'react-redux';

// import { setId } from '../toolkit/idSlice';
// import "../App.css";

// function Cell(props) {
//     let line_h = props.line_h 
//     let line_v = props.line_v 
//     return (
//         <div className="interCell">
//             <svg height="50" width="50">
//                 <line x1={line_h.x1} y1={line_h.y1} x2={line_h.x2} y2={line_h.y2} className="line" stroke="rgb(10, 10, 10)" strokeWidth={2} />
//                 <line x1={line_v.x1} y1={line_v.y1} x2={line_v.x2} y2={line_v.y2} className="line" stroke="rgb(10, 10, 10)" strokeWidth={2} />
//                 Sorry, your browser does not support inline SVG.
//             </svg>
//         </div>
//     );
//   }
  
// function Piece(props) {
//     let line_h = props.line_h 
//     let line_v = props.line_v 
    
//     let user = props.user;
//     let colour = "white";
//     if (user == props.userId) colour = props.userColour;
//     else colour = props.aiColour;
//     return (
//         <div className={"interCell"}>
//             <svg height="50" width="50">
//                 <line x1={line_h.x1} y1={line_h.y1} x2={line_h.x2} y2={line_h.y2} className="line" stroke="rgb(10, 10, 10)" strokeWidth={2} />
//                 <line x1={line_v.x1} y1={line_v.y1} x2={line_v.x2} y2={line_v.y2} className="line" stroke="rgb(10, 10, 10)" strokeWidth={2} />
//                 Sorry, your browser does not support inline SVG.
//             </svg>
//             <div className={"interCell-fill " + colour}></div>
//         </div>
//     );
// }

// function Go(props) {
    
  
//   let board = [
//     [-1, 0, -1, 1], 
//     [1, 1, 1, -1],
//     [1, -1, 1, -1],
//     [-1, 1, -1, 1]
//   ]

//   const getPiece = (i, j) => {
//     let line_h = {
//         x1: 0, y1: 25, x2: 50, y2: 25
//     }

//     let line_v = {
//         x1: 25, y1: 0, x2: 25, y2: 50
//     }

//     switch (i) {
//         case 0: 
//             line_v.y1 = 25; 
//             break; 
//         case 3: 
//             line_v.y2 = 25;
//             break; 
//         default: break;
//     }

//     switch (j) {
//         case 0: 
//             line_h.x1 = 25;
//             break; 
//         case 3: 
//             line_h.x2 = 25;
//             break; 
//         default: break;
//     }

//     if (board[i][j] == 0) {
//         return <Cell line_h={line_h} line_v={line_v}/>;
//     } else {
//         return <Piece user={board[i][j]} userColour="blue" aiColour="black" userId={1} 
//             line_h={line_h} line_v={line_v}
//         />
//     }
//   }

//   return (
//     <div>
//         <div>
//             <div className="board">
//                 <div className="yellow-board">
//                 { board.map((title, i) => {
//                 return (
//                     <div className="row">
//                     {board[i].map((text, j) => {
//                         return <div className="row">{getPiece(i, j)}</div>;
//                     })}
//                     </div>
//                 );
//                 })}
//                 </div>
//             </div>
//         </div>
//     </div>
//   );
// }

// export default Go;

import React,{ useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import Board from "../utils/Board.js";
import "../App.css";

function Go(props) {

    let size = parseInt(props.size) 
    let userId = useSelector((state) => state).id
    const [id, setId] = useState(userId)
    let navigate = useNavigate()

    // for restoring on refresh 
    if (userId == 0) {
        const urlParams = window.location.href.split('/')
        let session = urlParams[urlParams.length - 1]

        axios.post(`https://api.andrewdong.me/go/get_state`, {
            session_id: session 
        }).then((res) => {
            console.log(res['data'])
            userId = res['data']['user_player_id']
            setId(userId)
        }).catch((err) => {
            navigate('/page_not_found')
        });
    } else {
        window.localStorage.setItem("USER", userId);
    }

    const aiId = id == 1 ? -1 : 1
    console.log(id, aiId)

    return (
        <div>
        { id != 0 && aiId != null &&
            <div>
                <Board
                    rows={size}
                    cols={size}
                    route_0={`go/get_state`}
                    route_1={`go/do_user_action`}
                    route_2={`go/do_ai_action`}
                    clean={`go/delete_session`}
                    undo={`go/undo`}
                    name="go"
                    userId={id} 
                    aiId={aiId}
                />
            </div>
        }
        </div>
    );
}

export default Go;
