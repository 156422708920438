import React from "react";
import "../App.css";
import github from "./github.png"

function About(props) {
    return (
        <div className="about">

            <div className="about_person">
                <div className="person_row">
                    <h3 className="person">Andrew Dong</h3>
                    <a href="https://github.com/Andycipation"><img src={github} alt="github" className="icon"/></a>
                </div>
                <p>UWaterloo Computer Science</p>
            </div>

            <div className="about_person">
                <div className="person_row">
                    <h3 className="person">Ada Wang</h3>
                    <a href="https://github.com/adabingw"><img src={github} alt="github" className="icon"/></a>
                </div>
                <p>UWaterloo Software Engineering</p>
            </div>
        </div>
    );
}

export default About;
