import React, { useState } from "react"; 

function NavElement(props) {
    let focus = props.focus; 
    console.log(props.name, " is ", props.focus)
    return (
        <div className={focus} onClick={() => props.onClick(props.id)}>
            <div className="navElement">
                {props.name}
            </div>
        </div>
    )
}

export default NavElement;