import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Othello from "./othello/Othello.js";
import OthelloMenu from "./othello/OthelloMenu.js"

import Go from "./go/Go.js"
import GoMenu from "./go/GoMenu.js";

import Error from "./slides/Error.js";
import NavElement from "./utils/NavElement.js"; 

import "./App.css";

import About from "./slides/About.js"

function App() {
  const [session, setSession] = useState(null) 
  const [active, setActive] = useState(0)
  const [game, setGame] = useState("othello")
  let navigate = useNavigate()

  // gets session_id generated from child component
  const childrenClick = (data) => {
    console.log("session id passed from children: ", data);
    setSession(data);
  }

  console.log("sesion id: ", session)

  // on first render, try to get previous session_id
  // should be the current one or null, as session_id should be destroyed from
  // session storage once session is over 
  useEffect(() => {
    if (session == null) {
      const urlParams = window.location.href.split('/')
      let session_id = urlParams[urlParams.length - 1]
      console.log(urlParams, session_id)
      if (session_id != '' && 
          session_id != "othello" && 
          session_id != "connect4" && 
          session_id != "go" &&
          session_id != "about") 
        setSession(session_id)
    }

    if (active == 0) navigate("/othello")
  }, [])

  const gameClick = (id) => {
    console.log("gameClicked")
    console.log(session)
    if (session != null) {
      if (window.confirm("Leave game? Your progress will not be saved :(")) {
        console.log("Leaving game")
        setActive(id); 
        setSession(null)
        if (id == 0) {
          setGame("othello")
          navigate("/othello")
        } else if (id == 1) {
          setGame("connect4")
          navigate("/connect4")
        } else if (id == 2) {
          setGame("go")
          navigate("/go")
        } else if (id == 3) {
          navigate("/about")
        }
      } else {
        console.log("not leaving")
      }
    } else {
      setActive(id); 
      setSession(null)
      if (id == 0) {
        setGame("othello")
        navigate("/othello")
      } else if (id == 1) {
        setGame("connect4")
        navigate("/connect4")
      } else if (id == 2) {
        setGame("go")
        navigate("/go")
      } else if (id == "3") {
        navigate("/about")
      }
    }
  }

  return (
    <div className="App">
      <p className="title">iago.</p>
        <div className="nav">
            <NavElement name="othello." onClick={gameClick} focus={active == 0 ? "focus" : "unfocus"} id={0} />
            {/* <NavElement name="connect 4." onClick={gameClick}  focus={active == 1 ? "focus" : "unfocus"} id={1}/> */}
            <NavElement name="go." onClick={gameClick}  focus={active == 2 ? "focus" : "unfocus"} id={2}/>
            <NavElement name="about." onClick={gameClick}  focus={active == 3 ? "focus" : "unfocus"} id={3}/>
        </div>
        <Routes>
          <Route exact path="/othello" element={<OthelloMenu upperFunction={childrenClick} />} />
          <Route exact path={`/othello_6/${session}`} element={<Othello size="6"/>} />
          <Route exact path={`/othello_8/${session}`} element={<Othello size="8"/>} />

          <Route exact path={`/go`} element={<GoMenu upperFunction={childrenClick} />} />
          <Route exact path={`/go_7/${session}`} element={<Go size="7"/>} />
          <Route exact path={`/go_9/${session}`} element={<Go size="9"/>} />

          <Route exact path="/connect4" element={<Error message="coming soon!"/>} />
          <Route exact path="/page_not_found" element={<Error message="error. page not found."/>} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/error" element={<Error message="error. something went wrong."/>} />
        </Routes>
    </div>
  );
}

export default App;
