import React,{ useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { setId } from '../toolkit/idSlice';
import "../App.css";

function OthelloMenu(props) {
    
  const dispatch = useDispatch();
  const [size, setSize] = useState(0)
  const navigate = useNavigate();

  // once clicked, get new session id and start game 
  const handleClick = (id) => {
    dispatch(setId(id))
    axios.post(`https://api.andrewdong.me/othello/new_session?n=${size}&user_player_id=${id}`, {
      
    }).then((res) => {
      console.log(res['data'])
      window.sessionStorage.setItem("session_id", res['data']['session_id']);
      props.upperFunction(res['data']['session_id'])
      navigate(`/othello_${size}/${res['data']['session_id']}`); 
    }).catch((err) => {
      console.log(err);
      navigate("/error")
    });
  }

  const getMenu = () => {
    if (size == 0) {
      return (
        <div className="player_pick">
          <button className="button" onClick={() => setSize(6)}>6 × 6.</button>
          <button className="button" onClick={() => setSize(8)}>8 × 8.</button>
      </div>
      )
    } else if (size != undefined) {
      return (
        <div className="player_pick">
          <button className="button" onClick={() => handleClick(1)}>play first.</button>
          <button className="button" onClick={() => handleClick(-1)}>play second.</button>
      </div>
      )
    }
  }

  return (
    <div>
        {getMenu()}
    </div>
  );
}

export default OthelloMenu;
