import React,{ useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import Board from "../utils/Board.js";
import "../App.css";

function Othello(props) {

  let size = parseInt(props.size) 
  let userId = useSelector((state) => state).id
  const [id, setId] = useState(userId)

  // for restoring on refresh 
  if (userId == 0) {
    const urlParams = window.location.href.split('/')
    let session = urlParams[urlParams.length - 1]

    axios.post(`https://api.andrewdong.me/othello/get_state`, {
        session_id: session 
    }).then((res) => {
      console.log(res['data'])
      userId = res['data']['user_player_id']
      setId(userId)
    }).catch((err) => {
      // navigate('/page_not_found')
    });
  } else {
    window.localStorage.setItem("USER", userId);
  }

  const aiId = id == 1 ? -1 : 1
  console.log(id, aiId)

  return (
    <div>
      { id != 0 && aiId != null &&
        <div>
            <Board 
              rows={size}
              cols={size}
              route_0={`othello/get_state`}
              route_1={`othello/do_user_action`}
              route_2={`othello/do_ai_action`}
              clean={`othello/delete_session`}
              undo={`othello/undo`}
              name="othello"
              userId={id} 
              aiId={aiId}
            />
        </div>
      }
    </div>
  );
}

export default Othello;
